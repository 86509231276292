import httpManager from "../axios/httpManager";

export function getSportsGameList(search, pageNum, pageSize, orderBy) {
    return httpManager.post('/sports/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy, search)
}

export function getOnGamingLeagues(search) {
    return httpManager.post('/sports/get_leagues', search)
}

export function getSportsGameById(id) {
    return httpManager.get('/sports/get_by_id?id=' + id)
}

export function updateStatus(record) {
    return httpManager.post('/sports/update_status', record)
}
export function updateGameResultCancel(record) {
    return httpManager.post('/sports/update_game_result_cancel', record)
}
export function gameResultReset(record) {
    return httpManager.post('/sports/game_result_reset', record)
}
export function initGame(id) {
    return httpManager.get('/sports/init_game?gameId=' + id)
}
export function confirmResult() {
    return httpManager.get('/sports/confirm_result')
}
export function liquidation() {
    return httpManager.get('/sports/bet/liquidation')
}
export function updateGameDisplay(record) {
    return httpManager.post('/sports/update_game_display', record)
}
export function getById(id) {
    return httpManager.get('/sports/get_by_id?id='+id)
}
export function updateGameResult(record) {
    return httpManager.post('/sports/update_game_result', record)
}
export function updateInplayGameScores (game) {
    return httpManager.post('/sports/update_inplay_game_scores', game);
}
export function getLeagueList  (search, pageNum, pageSize, orderBy) {
    let url = '/league/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}
export function saveGame  (game) {
    return httpManager.post('/sports/save', game);
}
export function updateGame  (game) {
    return httpManager.post('/sports/update', game);
}