<template>
    <el-dialog
            :title="title"
            :visible.sync="centerDialogVisible"
            width="30%"
            modal
            center @close="close">
        <div style="text-align: center;padding: 15px">
            베팅수:{{game.betQuantity|comma}} 베팅금액:{{game.betCashAmount|comma}}
        </div>

        <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
            <el-input type="number" v-model="homeScore" size="mini" placeholder="T1 스코어"></el-input>
            <span style="display: inline-block;width: 10px"> </span>
            <el-input type="number" v-model="awayScore" size="mini" placeholder="T2 스코어"></el-input>
        </div>
        <div style="text-align: center;width: 100%;padding: 10px">
            <el-checkbox v-model="sysn">
                핸디캡/오버언더 경기 동일`스코어`처리
            </el-checkbox>
        </div>

        <span slot="footer" class="dialog-footer">
        <el-button @click="close">취소</el-button>
        <el-button type="primary" @click="setScore">확인</el-button>
        </span>
    </el-dialog>

</template>

<script>
    import {getById, updateGameResult} from "../../../network/manager/sportsRequest";

    export default {
        name: "SportsScoreToast",
        data() {
            return {
                gameId: null, //
                duration: 0, //
                closed: false,
                centerDialogVisible: false,
                game: {},
                title: '',
                homeScore: null,
                awayScore: null,
                sysn: false
            }
        },
        created() {
            this.centerDialogVisible = true
            getById(this.gameId).then(res => {
                if (res.data.success) {
                    this.game = res.data.data
                    this.title = '('+this.game.league.leagueName + ') '+ this.game.homeTeamName + ' : ' + this.game.awayTeamName
                }

            })
        },
        mounted() {

        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.game= {}
                    this.visible = false
                    this.destroyElement()
                }
            }
        },
        methods: {
            close() {
                this.game = {}
                this.closed = true
                this.centerDialogVisible = false
            },
            setScore() {
                let record = {}
                record.id = this.gameId
                record.homeTeamScore = this.homeScore
                record.awayTeamScore = this.awayScore
                updateGameResult(record).then(res => {
                    if (res.data.success) {
                        let gameResult = 0
                        getById(this.gameId).then(res => {
                            gameResult = res.data.data.gameResult
                            this.$bus.$emit('sportsScoreSuccess', {
                                'gameId': this.gameId,
                                'hs': this.homeScore,
                                'as': this.awayScore,
                                'gameResult': gameResult
                            })
                        })

                    } else {
                        this.$bus.$emit('sportsScoreSuccess', {'gameId': 0, 'hs': this.homeScore, 'as': this.awayScore})
                    }
                })

                this.close();

            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
        }
    }
</script>

<style scoped>

</style>